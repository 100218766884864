import React, {FormEvent, useContext, useMemo, useState} from 'react'
import {Link, useNavigate} from "react-router-dom";
import {AuthContext} from "../context/auth";

export const SignupPage = function () {
    const { setToken } = useContext(AuthContext);
    const navigate = useNavigate()

    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')

    const [signingUp, setSigningUp] = useState(false);
    const [error, setError] = useState('');

    const canSignup = useMemo(() => {
        return !!(fullName && email && username && password && password2);
    }, [fullName, email, username, password, password2]);

    const submitHandler = async function (e: FormEvent) {
        e.preventDefault();

        if (!fullName) {
            alert("Please fill in your full name");
            return;
        }
        if (!email) {
            alert("Please fill in an email address");
            return;
        }
        if (!username) {
            alert("Please fill in a username");
            return;
        }
        if (!password) {
            alert("Please fill in a password");
            return;
        }
        if (password !== password2) {
            alert("Passwords do not match");
            return;
        }

        setSigningUp(true);
        const response = await fetch(`/v1/user/signup`, {
            method: 'POST',
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                full_name: fullName,
                email,
                username,
                password,
            })
        });
        if (response.status !== 200) {
            const err = await response.text();
            setError(`Failed signing up: ${err}`);
            setSigningUp(false);
            return
        }

        const body = await response.json();
        setToken(body.token);
        navigate('/junglebus')

        setSigningUp(false);
        return true;
    }

    return (
        <section
            className="relative bg-white overflow-hidden"
            style={{
                backgroundImage: "url('/images/flex-ui/pattern-white.svg')", backgroundPosition: "center",
            }}
        >
            <div className="py-20 md:py-28">
                <div className="container px-4 mx-auto">
                    <div className="flex flex-wrap xl:items-center -mx-4">
                        <div className="w-full md:w-3/5 lg:w-1/2">
                            <div className="max-w-sm mx-auto">
                                <div className="mb-6 text-center">
                                    <Link className="inline-block mb-6" to="/">
                                        <img className="h-16" src="/images/flex-ui/flex-circle-green.svg" alt=""/>
                                    </Link>
                                    <h3 className="mb-4 text-2xl md:text-3xl font-bold">
                                        Signup for JungleBus
                                    </h3>
                                    <p className="text-lg text-coolGray-500 font-medium">
                                        Start exploring the data on the Bitcoin blockchain
                                    </p>
                                    {!!error && (
                                        <h4 className="mb-4 text-md md:text-xl font-bold text-red-500">
                                            {error}
                                        </h4>
                                    )}
                                </div>
                                <form onSubmit={submitHandler}>
                                    <div className="mb-6">
                                        <label
                                            className="block mb-2 text-coolGray-800 font-medium"
                                            htmlFor="full_name"
                                        >
                                            Full Name*
                                        </label>
                                        <input
                                            className="appearance-none block w-full p-3 leading-5 text-coolGray-900 border border-coolGray-200 rounded-lg shadow-md placeholder-coolGray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                                            id="full_name"
                                            type="name"
                                            value={fullName}
                                            required={true}
                                            placeholder="Fill in your full name"
                                            onChange={(e) => setFullName(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-6">
                                        <label
                                            className="block mb-2 text-coolGray-800 font-medium"
                                            htmlFor="email"
                                        >
                                            Email*
                                        </label>
                                        <input
                                            className="appearance-none block w-full p-3 leading-5 text-coolGray-900 border border-coolGray-200 rounded-lg shadow-md placeholder-coolGray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                                            type="name"
                                            id="email"
                                            value={email}
                                            required={true}
                                            placeholder="Fill in your email address"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-6">
                                        <label
                                            className="block mb-2 text-coolGray-800 font-medium"
                                            htmlFor="username"
                                        >
                                            Username*
                                        </label>
                                        <input
                                            className="appearance-none block w-full p-3 leading-5 text-coolGray-900 border border-coolGray-200 rounded-lg shadow-md placeholder-coolGray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                                            type="name"
                                            id="username"
                                            value={username}
                                            required={true}
                                            placeholder="Choose a username"
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label
                                            className="block mb-2 text-coolGray-800 font-medium"
                                            htmlFor="password"
                                        >
                                            Password*
                                        </label>
                                        <input
                                            className="appearance-none block w-full p-3 leading-5 text-coolGray-900 border border-coolGray-200 rounded-lg shadow-md placeholder-coolGray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                                            type="password"
                                            id="password"
                                            value={password}
                                            required={true}
                                            placeholder="************"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label
                                            className="block mb-2 text-coolGray-800 font-medium"
                                            htmlFor="password2"
                                        >
                                            Repeat Password*
                                        </label>
                                        <input
                                            className="appearance-none block w-full p-3 leading-5 text-coolGray-900 border border-coolGray-200 rounded-lg shadow-md placeholder-coolGray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                                            type="password"
                                            id="password2"
                                            value={password2}
                                            required={true}
                                            placeholder="************"
                                            onChange={(e) => setPassword2(e.target.value)}
                                        />
                                    </div>
                                    <button
                                        className="inline-block py-3 px-7 mb-4 w-full text-base text-green-50 font-medium text-center leading-6 bg-green-500 hover:bg-green-600 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 rounded-md shadow-sm"
                                        type="submit"
                                        disabled={signingUp || !canSignup}
                                    >
                                        Sign Up
                                    </button>
                                    <p className="text-center">
                                        <span className="text-xs font-medium">Already have an account? </span>
                                        <Link
                                            className="inline-block text-xs font-medium text-green-500 hover:text-green-600 hover:underline"
                                            to={"/login"}
                                        >
                                            Sign In
                                        </Link>
                                    </p>
                                </form>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 px-4">
                            <div className="relative mx-auto md:mr-0 max-w-max">
                                <img
                                    className="absolute z-10 -left-14 -top-12 w-28 md:w-auto"
                                    src="/images/flex-ui/circle3-yellow.svg" alt=""
                                />
                                <img
                                    className="absolute z-10 -right-7 -bottom-8 w-28 md:w-auto"
                                    src="/images/flex-ui/dots3-blue.svg" alt=""
                                />
                                <img
                                    className="relative rounded-7xl"
                                    src="/images/junglebus-jpeg-1660049038186.webp" alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
