import React from "react";
import { Line } from "react-chartjs-2";
import { chartConfigs } from "../chart-configs";

type ChartInput = {
  labels: any;
  datasets: any;
  height?: number;
}

export const ChartLine = function(
  {
    labels,
    datasets,
    height,
  }: ChartInput) {

  const { data, options } = chartConfigs(labels || [], datasets || {});

  return (
    <div className="w-full p-3 rounded-md" style={{ height: height || 480, backgroundColor: '#2373b9' }}>
      <Line data={data} options={options} />
    </div>
  )
}
