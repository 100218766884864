import React, { useEffect } from 'react';


export const DocsPage = function () {
  useEffect(() => {
    document.location.href = "https://www.npmjs.com/package/@gorillapool/js-junglebus";
  });

  return (<>
    Redirecting...
  </>);
};
