import React, {useContext} from 'react'
import {Link} from "react-router-dom";
import {AuthContext} from "../context/auth";

export const HomePage = function () {
    const { authenticated } = useContext(AuthContext);

    return (
        <>
            <section
                className="relative bg-white overflow-hidden"
                style={{
                    backgroundImage: "url('/images/flex-ui/pattern-white.svg')",
                    backgroundPosition: "center",
                }}
            >
                <div className="py-20 md:py-28">
                    <div className="container px-4 mx-auto">
                        <div className="flex flex-wrap xl:items-center -mx-4">
                            <div className="w-full md:w-1/2 px-4 mb-16 md:mb-0">
                                <h1 className="mb-6 text-3xl md:text-5xl lg:text-6xl leading-tight font-bold tracking-tight">
                                    GorillaPool JungleBus is the way to access all data on the Bitcoin blockchain
                                </h1>
                                <p className="mb-8 text-lg md:text-xl text-coolGray-500 font-medium">
                                    JungleBus is an enterprise service for accessing all transactions on the Bitcoin
                                    blockchain.
                                </p>
                                <div className="flex flex-wrap">
                                    {authenticated
                                        ?
                                        <div className="w-full md:w-auto py-1 md:py-0 md:mr-4">
                                            <Link
                                                to={"/junglebus"}
                                                className="inline-block py-5 px-7 w-full text-base md:text-lg leading-4 text-green-50 font-medium text-center bg-green-500 hover:bg-green-600 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 border border-green-500 rounded-md shadow-sm"
                                            >
                                                Dashboard
                                            </Link>
                                        </div>
                                        :
                                        <>
                                            <div className="w-full md:w-auto py-1 md:py-0 md:mr-4">
                                                <Link
                                                    to={"/login"}
                                                    className="inline-block py-5 px-7 w-full text-base md:text-lg leading-4 text-green-50 font-medium text-center bg-green-500 hover:bg-green-600 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 border border-green-500 rounded-md shadow-sm"
                                                >
                                                    Login
                                                </Link>
                                            </div>
                                            <div className="w-full md:w-auto py-1 md:py-0">
                                                <Link
                                                    to={"/signup"}
                                                    className="inline-block py-5 px-7 w-full text-base md:text-lg leading-4 text-coolGray-800 font-medium text-center bg-white hover:bg-coolGray-100 focus:ring-2 focus:ring-coolGray-200 focus:ring-opacity-50 border border-coolGray-200 rounded-md shadow-sm"
                                                >
                                                    Sign Up
                                                </Link>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="w-full md:w-1/2 px-4">
                                <div className="relative mx-auto md:mr-0 max-w-max">
                                    <img
                                        className="absolute z-10 -left-14 -top-12 w-28 md:w-auto"
                                        src="/images/flex-ui/circle3-yellow.svg" alt=""
                                    />
                                    <img
                                        className="absolute z-10 -right-7 -bottom-8 w-28 md:w-auto"
                                        src="/images/flex-ui/dots3-blue.svg" alt=""
                                    />
                                    <img
                                        className="relative rounded-7xl"
                                        src="/images/junglebus-jpeg-1660049038186.webp" alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="py-24 bg-white overflow-hidden"
                style={{
                    backgroundImage: "url('/images/flex-ui/pattern-white.svg')",
                    backgroundPosition: "center"
                }}>
                <div className="container px-4 mx-auto">
                    <div className="md:max-w-4xl mb-16 md:mb-20">
                        <h1 className="mb-4 text-3xl md:text-4xl leading-tight font-bold tracking-tighter">
                            Get access to all data on the Bitcoin blockchain
                        </h1>
                        <p className="text-lg md:text-xl text-coolGray-500 font-medium">
                            A powerful, low-level indexer you can trust to power your lightweight bitcoin data sets!
                        </p>
                    </div>
                    <div className="flex flex-wrap lg:items-center -mx-4">
                        <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0">
                            <div className="relative mx-auto md:ml-0 max-w-max">
                                <img
                                    className="absolute z-10 -left-8 -top-8 w-28 md:w-auto text-yellow-400"
                                    src="/images/flex-ui/circle3-yellow.svg"
                                    alt=""
                                />
                                <img
                                    className="absolute z-10 -right-7 -bottom-8 w-28 md:w-auto text-blue-500"
                                    src="/images/flex-ui/dots3-blue.svg"
                                    alt=""
                                />
                                <img
                                    src="/images/flex-ui/stock2.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 px-4">
                            <div
                                className="flex flex-wrap p-8 text-center md:text-left hover:bg-white rounded-md hover:shadow-xl transition duration-200">
                                <div className="w-full md:w-auto mb-6 md:mb-0 md:pr-6">
                                    <div
                                        className="inline-flex h-14 w-14 mx-auto items-center justify-center text-white bg-green-500 rounded-lg">
                                        <svg width="21" height="21" viewBox="0 0 24 21" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M17 18.63H5C4.20435 18.63 3.44129 18.3139 2.87868 17.7513C2.31607 17.1887 2 16.4257 2 15.63V7.63C2 7.36479 1.89464 7.11043 1.70711 6.9229C1.51957 6.73536 1.26522 6.63 1 6.63C0.734784 6.63 0.48043 6.73536 0.292893 6.9229C0.105357 7.11043 0 7.36479 0 7.63L0 15.63C0 16.9561 0.526784 18.2279 1.46447 19.1655C2.40215 20.1032 3.67392 20.63 5 20.63H17C17.2652 20.63 17.5196 20.5246 17.7071 20.3371C17.8946 20.1496 18 19.8952 18 19.63C18 19.3648 17.8946 19.1104 17.7071 18.9229C17.5196 18.7354 17.2652 18.63 17 18.63ZM21 0.630005H7C6.20435 0.630005 5.44129 0.946075 4.87868 1.50868C4.31607 2.07129 4 2.83436 4 3.63V13.63C4 14.4257 4.31607 15.1887 4.87868 15.7513C5.44129 16.3139 6.20435 16.63 7 16.63H21C21.7956 16.63 22.5587 16.3139 23.1213 15.7513C23.6839 15.1887 24 14.4257 24 13.63V3.63C24 2.83436 23.6839 2.07129 23.1213 1.50868C22.5587 0.946075 21.7956 0.630005 21 0.630005ZM20.59 2.63L14.71 8.51C14.617 8.60373 14.5064 8.67813 14.3846 8.7289C14.2627 8.77966 14.132 8.8058 14 8.8058C13.868 8.8058 13.7373 8.77966 13.6154 8.7289C13.4936 8.67813 13.383 8.60373 13.29 8.51L7.41 2.63H20.59ZM22 13.63C22 13.8952 21.8946 14.1496 21.7071 14.3371C21.5196 14.5246 21.2652 14.63 21 14.63H7C6.73478 14.63 6.48043 14.5246 6.29289 14.3371C6.10536 14.1496 6 13.8952 6 13.63V4L11.88 9.88C12.4425 10.4418 13.205 10.7574 14 10.7574C14.795 10.7574 15.5575 10.4418 16.12 9.88L22 4V13.63Z"
                                                fill="currentColor"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="w-full md:flex-1 md:pt-3">
                                    <h3 className="mb-4 text-xl md:text-2xl leading-tight text-coolGray-900 font-bold">
                                        Rely on the most powerful node in the history of bitcoin
                                    </h3>
                                    <p className="text-coolGray-500 font-medium">
                                        JungleBus is filtered from the highest performance nodes ever.
                                    </p>
                                </div>
                            </div>
                            <div
                                className="flex flex-wrap p-8 text-center md:text-left hover:bg-white rounded-md hover:shadow-xl transition duration-200">
                                <div className="w-full md:w-auto mb-6 md:mb-0 md:pr-6">
                                    <div
                                        className="inline-flex h-14 w-14 mx-auto items-center justify-center text-white bg-green-500 rounded-lg">
                                        <svg width="21" height="21" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5 18H9.24C9.37161 18.0008 9.50207 17.9755 9.62391 17.9258C9.74574 17.876 9.85656 17.8027 9.95 17.71L16.87 10.78L19.71 8C19.8037 7.90704 19.8781 7.79644 19.9289 7.67458C19.9797 7.55272 20.0058 7.42201 20.0058 7.29C20.0058 7.15799 19.9797 7.02728 19.9289 6.90542C19.8781 6.78356 19.8037 6.67296 19.71 6.58L15.47 2.29C15.377 2.19627 15.2664 2.12188 15.1446 2.07111C15.0227 2.02034 14.892 1.9942 14.76 1.9942C14.628 1.9942 14.4973 2.02034 14.3754 2.07111C14.2536 2.12188 14.143 2.19627 14.05 2.29L11.23 5.12L4.29 12.05C4.19732 12.1434 4.12399 12.2543 4.07423 12.3761C4.02446 12.4979 3.99924 12.6284 4 12.76V17C4 17.2652 4.10536 17.5196 4.29289 17.7071C4.48043 17.8946 4.73478 18 5 18ZM14.76 4.41L17.59 7.24L16.17 8.66L13.34 5.83L14.76 4.41ZM6 13.17L11.93 7.24L14.76 10.07L8.83 16H6V13.17ZM21 20H3C2.73478 20 2.48043 20.1054 2.29289 20.2929C2.10536 20.4804 2 20.7348 2 21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22H21C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21C22 20.7348 21.8946 20.4804 21.7071 20.2929C21.5196 20.1054 21.2652 20 21 20Z"
                                                fill="currentColor"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="w-full md:flex-1 md:pt-3">
                                    <h3 className="mb-4 text-xl md:text-2xl leading-tight text-coolGray-900 font-bold">
                                        Build Your Business With JungleBus!
                                    </h3>
                                    <p className="text-coolGray-500 font-medium">
                                        You do NOT need to run a bitcoin node. We serve you valuable data with simple filters, so you can focus on your business instead of node administration.
                                    </p>
                                </div>
                            </div>
                            <div
                                className="flex flex-wrap p-8 text-center md:text-left hover:bg-white rounded-md hover:shadow-xl transition duration-200">
                                <div className="w-full md:w-auto mb-6 md:mb-0 md:pr-6">
                                    <div
                                        className="inline-flex h-14 w-14 mx-auto items-center justify-center text-white bg-green-500 rounded-lg">
                                        <svg width="21" height="21" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10 13H3C2.73478 13 2.48043 13.1054 2.29289 13.2929C2.10536 13.4804 2 13.7348 2 14V21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22H10C10.2652 22 10.5196 21.8946 10.7071 21.7071C10.8946 21.5196 11 21.2652 11 21V14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13ZM9 20H4V15H9V20ZM21 2H14C13.7348 2 13.4804 2.10536 13.2929 2.29289C13.1054 2.48043 13 2.73478 13 3V10C13 10.2652 13.1054 10.5196 13.2929 10.7071C13.4804 10.8946 13.7348 11 14 11H21C21.2652 11 21.5196 10.8946 21.7071 10.7071C21.8946 10.5196 22 10.2652 22 10V3C22 2.73478 21.8946 2.48043 21.7071 2.29289C21.5196 2.10536 21.2652 2 21 2V2ZM20 9H15V4H20V9ZM21 13H14C13.7348 13 13.4804 13.1054 13.2929 13.2929C13.1054 13.4804 13 13.7348 13 14V21C13 21.2652 13.1054 21.5196 13.2929 21.7071C13.4804 21.8946 13.7348 22 14 22H21C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21V14C22 13.7348 21.8946 13.4804 21.7071 13.2929C21.5196 13.1054 21.2652 13 21 13ZM20 20H15V15H20V20ZM10 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V10C2 10.2652 2.10536 10.5196 2.29289 10.7071C2.48043 10.8946 2.73478 11 3 11H10C10.2652 11 10.5196 10.8946 10.7071 10.7071C10.8946 10.5196 11 10.2652 11 10V3C11 2.73478 10.8946 2.48043 10.7071 2.29289C10.5196 2.10536 10.2652 2 10 2V2ZM9 9H4V4H9V9Z"
                                                fill="currentColor"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="w-full md:flex-1 md:pt-3">
                                    <h3 className="mb-4 text-xl md:text-2xl leading-tight text-coolGray-900 font-bold">
                                        Tons of Data, Filtered!
                                    </h3>
                                    <p className="text-coolGray-500 font-medium">
                                        Filter by protocol tag and tons of optional variables with context and subcontext options.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*
            <section
                className="py-24 md:pb-28 bg-white"
                style={{
                    backgroundImage: "url('/images/flex-ui/pattern-white.svg')",
                    backgroundPosition: "center"
                }}
            >
                <div className="container px-4 mx-auto">
                    <div className="flex flex-wrap items-center justify-center lg:justify-between -mx-4">
                        <div className="w-auto px-4 order-last lg:order-first">
                            <button
                                className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-coolGray-50 hover:bg-coolGray-100">
                                <svg width="12" height="14" viewBox="0 0 12 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10.9999 6H3.4099L6.7099 2.71C6.8982 2.5217 7.00399 2.2663 7.00399 2C7.00399 1.7337 6.8982 1.47831 6.7099 1.29C6.52159 1.1017 6.2662 0.995911 5.9999 0.995911C5.73359 0.995911 5.4782 1.1017 5.2899 1.29L0.289896 6.29C0.198856 6.38511 0.127491 6.49725 0.0798963 6.62C-0.0201217 6.86346 -0.0201217 7.13654 0.0798963 7.38C0.127491 7.50275 0.198856 7.6149 0.289896 7.71L5.2899 12.71C5.38286 12.8037 5.49346 12.8781 5.61532 12.9289C5.73718 12.9797 5.86788 13.0058 5.9999 13.0058C6.13191 13.0058 6.26261 12.9797 6.38447 12.9289C6.50633 12.8781 6.61693 12.8037 6.7099 12.71C6.80363 12.617 6.87802 12.5064 6.92879 12.3846C6.97956 12.2627 7.00569 12.132 7.00569 12C7.00569 11.868 6.97956 11.7373 6.92879 11.6154C6.87802 11.4936 6.80363 11.383 6.7099 11.29L3.4099 8H10.9999C11.2651 8 11.5195 7.89465 11.707 7.70711C11.8945 7.51957 11.9999 7.26522 11.9999 7C11.9999 6.73479 11.8945 6.48043 11.707 6.2929C11.5195 6.10536 11.2651 6 10.9999 6Z"
                                        fill="#404F65"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="w-full lg:w-2/6 px-4 mb-12 lg:mb-0">
                            <div className="flex items-center">
                                <img
                                    className="w-20 md:w-24 h-20 md:h-24 mr-6 rounded-full"
                                    src="/images/flex-ui/teams/avatar1.png"
                                    alt=""
                                />
                                <div>
                                    <h3 className="mb-2 text-xl md:text-2xl font-semibold">Kurt Wuckert Jr.</h3>
                                    <span
                                        className="text-lg text-coolGray-500 font-medium">CEO &amp; Founder at Flex.co</span>
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-2/6 px-4 mb-12 lg:mb-0">
                            <div className="relative pt-12 pb-6 sm:p-6">
                                <img
                                    className="absolute top-0 left-0"
                                    src="/images/flex-ui/quote-top-green.svg"
                                    alt=""
                                />
                                <img
                                    className="absolute bottom-0 right-0"
                                    src="/images/flex-ui/quote-down-green.svg"
                                    alt=""
                                />
                                <div className="relative">
                                    <h2 className="text-2xl font-semibold tracking-tighter">
                                        The best solution for anyone who wants to work a flexible schedule but still
                                        earn a
                                        full-time income.
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="w-auto px-4 order-last">
                            <button
                                className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-coolGray-50 hover:bg-coolGray-100">
                                <svg width="12" height="14" viewBox="0 0 12 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.92 6.62C11.8724 6.49725 11.801 6.38511 11.71 6.29L6.71 1.29C6.61676 1.19676 6.50607 1.1228 6.38425 1.07234C6.26243 1.02188 6.13186 0.995911 6 0.995911C5.7337 0.995911 5.4783 1.1017 5.29 1.29C5.19676 1.38324 5.1228 1.49393 5.07234 1.61575C5.02188 1.73758 4.99591 1.86814 4.99591 2C4.99591 2.2663 5.1017 2.5217 5.29 2.71L8.59 6H1C0.734784 6 0.48043 6.10536 0.292893 6.2929C0.105357 6.48043 0 6.73479 0 7C0 7.26522 0.105357 7.51957 0.292893 7.70711C0.48043 7.89465 0.734784 8 1 8H8.59L5.29 11.29C5.19627 11.383 5.12188 11.4936 5.07111 11.6154C5.02034 11.7373 4.9942 11.868 4.9942 12C4.9942 12.132 5.02034 12.2627 5.07111 12.3846C5.12188 12.5064 5.19627 12.617 5.29 12.71C5.38296 12.8037 5.49356 12.8781 5.61542 12.9289C5.73728 12.9797 5.86799 13.0058 6 13.0058C6.13201 13.0058 6.26272 12.9797 6.38458 12.9289C6.50644 12.8781 6.61704 12.8037 6.71 12.71L11.71 7.71C11.801 7.6149 11.8724 7.50275 11.92 7.38C12.02 7.13654 12.02 6.86346 11.92 6.62Z"
                                        fill="#404F65"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            <section
                className="relative py-24 bg-white"
                style={{
                    backgroundImage: "url('/images/flex-ui/pattern-white.svg')",
                    backgroundPosition: "center"
                }}
            >
                <div className="relative container px-4 mx-auto">
                    <div className="mb-16 text-center">
                        <span
                            className="inline-block py-px px-2 mb-4 text-xs leading-5 text-green-500 bg-green-100 font-medium uppercase rounded-9xl"
                        >
                            Team
                        </span>
                        <h3 className="mb-4 text-3xl md:text-5xl leading-tight text-coolGray-900 font-bold tracking-tighter">
                            Our team is the best in the business.
                        </h3>
                        <p className="text-lg md:text-xl text-coolGray-500 font-medium">
                            Highly professional and capable of running your business across all digital channels.
                        </p>
                    </div>
                    <div className="flex flex-wrap -mx-4">
                        <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-10">
                            <div className="text-center max-w-xs mx-auto">
                                <img
                                    className="w-24 h-24 mx-auto mb-6 rounded-full"
                                    src="/images/flex-ui/teams/avatar1.png" alt=""
                                />
                                <h3 className="mb-1 text-lg text-coolGray-800 font-semibold">Macauley Herring</h3>
                                <span className="inline-block mb-6 text-lg font-medium text-green-500">
                                    CEO &amp; Founder
                                </span>
                                <div className="flex items-center justify-center">
                                    <a className="inline-block mr-5 text-coolGray-300 hover:text-coolGray-400" href="#">
                                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M6.63494 17.7273V9.76602H9.3583L9.76688 6.66246H6.63494V4.68128C6.63494 3.78301 6.88821 3.17085 8.20297 3.17085L9.87712 3.17017V0.394238C9.5876 0.357335 8.59378 0.272728 7.43708 0.272728C5.0217 0.272728 3.3681 1.71881 3.3681 4.37391V6.66246H0.636475V9.76602H3.3681V17.7273H6.63494Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </a>
                                    <a className="inline-block mr-5 text-coolGray-300 hover:text-coolGray-400" href="#">
                                        <svg width="19" height="16" viewBox="0 0 19 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M18.8181 2.14597C18.1356 2.44842 17.4032 2.65355 16.6336 2.74512C17.4194 2.27461 18.0208 1.5283 18.3059 0.641757C17.5689 1.07748 16.7553 1.39388 15.8885 1.56539C15.1943 0.824879 14.2069 0.363636 13.1118 0.363636C11.0108 0.363636 9.30722 2.06718 9.30722 4.16706C9.30722 4.46488 9.34083 4.75576 9.40574 5.03391C6.24434 4.87512 3.44104 3.36048 1.56483 1.05894C1.23686 1.61985 1.05028 2.27342 1.05028 2.97109C1.05028 4.29106 1.72243 5.45573 2.74225 6.13712C2.11877 6.11627 1.53237 5.94476 1.01901 5.65967V5.70718C1.01901 7.54979 2.33086 9.08761 4.07031 9.43761C3.75161 9.52336 3.41555 9.57088 3.06789 9.57088C2.82222 9.57088 2.58464 9.54655 2.35171 9.50018C2.8361 11.0125 4.24067 12.1123 5.90483 12.1424C4.6034 13.1622 2.96243 13.7683 1.1801 13.7683C0.873008 13.7683 0.570523 13.7498 0.272705 13.7162C1.95655 14.7974 3.95561 15.4278 6.10416 15.4278C13.1026 15.4278 16.928 9.63115 16.928 4.60397L16.9153 4.11145C17.6627 3.57833 18.3094 2.90851 18.8181 2.14597Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </a>
                                    <a className="inline-block text-coolGray-300 hover:text-coolGray-400" href="#">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M5.6007 0.181818H14.3992C17.3874 0.181818 19.8184 2.61281 19.8182 5.60074V14.3993C19.8182 17.3872 17.3874 19.8182 14.3992 19.8182H5.6007C2.61276 19.8182 0.181885 17.3873 0.181885 14.3993V5.60074C0.181885 2.61281 2.61276 0.181818 5.6007 0.181818ZM14.3993 18.0759C16.4267 18.0759 18.0761 16.4266 18.0761 14.3993H18.076V5.60074C18.076 3.57348 16.4266 1.92405 14.3992 1.92405H5.6007C3.57343 1.92405 1.92412 3.57348 1.92412 5.60074V14.3993C1.92412 16.4266 3.57343 18.0761 5.6007 18.0759H14.3993ZM4.85721 10.0001C4.85721 7.16424 7.16425 4.85714 10.0001 4.85714C12.8359 4.85714 15.1429 7.16424 15.1429 10.0001C15.1429 12.8359 12.8359 15.1429 10.0001 15.1429C7.16425 15.1429 4.85721 12.8359 4.85721 10.0001ZM6.62805 10C6.62805 11.8593 8.14081 13.3719 10.0001 13.3719C11.8593 13.3719 13.3721 11.8593 13.3721 10C13.3721 8.14058 11.8594 6.6279 10.0001 6.6279C8.14069 6.6279 6.62805 8.14058 6.62805 10Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-10">
                            <div className="text-center max-w-xs mx-auto">
                                <img
                                    className="w-24 h-24 mx-auto mb-6 rounded-full"
                                    src="/images/flex-ui/teams/avatar2.png"
                                    alt=""
                                />
                                <h3 className="mb-1 text-lg text-coolGray-800 font-semibold">Ivan Mathews</h3>
                                <span className="inline-block mb-6 text-lg font-medium text-green-500">CTO</span>
                                <div className="flex items-center justify-center">
                                    <a className="inline-block mr-5 text-coolGray-300 hover:text-coolGray-400"
                                       href="#">
                                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M6.63494 17.7273V9.76602H9.3583L9.76688 6.66246H6.63494V4.68128C6.63494 3.78301 6.88821 3.17085 8.20297 3.17085L9.87712 3.17017V0.394238C9.5876 0.357335 8.59378 0.272728 7.43708 0.272728C5.0217 0.272728 3.3681 1.71881 3.3681 4.37391V6.66246H0.636475V9.76602H3.3681V17.7273H6.63494Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </a>
                                    <a className="inline-block mr-5 text-coolGray-300 hover:text-coolGray-400"
                                       href="#">
                                        <svg width="19" height="16" viewBox="0 0 19 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M18.8181 2.14597C18.1356 2.44842 17.4032 2.65355 16.6336 2.74512C17.4194 2.27461 18.0208 1.5283 18.3059 0.641757C17.5689 1.07748 16.7553 1.39388 15.8885 1.56539C15.1943 0.824879 14.2069 0.363636 13.1118 0.363636C11.0108 0.363636 9.30722 2.06718 9.30722 4.16706C9.30722 4.46488 9.34083 4.75576 9.40574 5.03391C6.24434 4.87512 3.44104 3.36048 1.56483 1.05894C1.23686 1.61985 1.05028 2.27342 1.05028 2.97109C1.05028 4.29106 1.72243 5.45573 2.74225 6.13712C2.11877 6.11627 1.53237 5.94476 1.01901 5.65967V5.70718C1.01901 7.54979 2.33086 9.08761 4.07031 9.43761C3.75161 9.52336 3.41555 9.57088 3.06789 9.57088C2.82222 9.57088 2.58464 9.54655 2.35171 9.50018C2.8361 11.0125 4.24067 12.1123 5.90483 12.1424C4.6034 13.1622 2.96243 13.7683 1.1801 13.7683C0.873008 13.7683 0.570523 13.7498 0.272705 13.7162C1.95655 14.7974 3.95561 15.4278 6.10416 15.4278C13.1026 15.4278 16.928 9.63115 16.928 4.60397L16.9153 4.11145C17.6627 3.57833 18.3094 2.90851 18.8181 2.14597Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </a>
                                    <a className="inline-block text-coolGray-300 hover:text-coolGray-400" href="#">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M5.6007 0.181818H14.3992C17.3874 0.181818 19.8184 2.61281 19.8182 5.60074V14.3993C19.8182 17.3872 17.3874 19.8182 14.3992 19.8182H5.6007C2.61276 19.8182 0.181885 17.3873 0.181885 14.3993V5.60074C0.181885 2.61281 2.61276 0.181818 5.6007 0.181818ZM14.3993 18.0759C16.4267 18.0759 18.0761 16.4266 18.0761 14.3993H18.076V5.60074C18.076 3.57348 16.4266 1.92405 14.3992 1.92405H5.6007C3.57343 1.92405 1.92412 3.57348 1.92412 5.60074V14.3993C1.92412 16.4266 3.57343 18.0761 5.6007 18.0759H14.3993ZM4.85721 10.0001C4.85721 7.16424 7.16425 4.85714 10.0001 4.85714C12.8359 4.85714 15.1429 7.16424 15.1429 10.0001C15.1429 12.8359 12.8359 15.1429 10.0001 15.1429C7.16425 15.1429 4.85721 12.8359 4.85721 10.0001ZM6.62805 10C6.62805 11.8593 8.14081 13.3719 10.0001 13.3719C11.8593 13.3719 13.3721 11.8593 13.3721 10C13.3721 8.14058 11.8594 6.6279 10.0001 6.6279C8.14069 6.6279 6.62805 8.14058 6.62805 10Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-10">
                            <div className="text-center max-w-xs mx-auto">
                                <img
                                    className="w-24 h-24 mx-auto mb-6 rounded-full"
                                    src="/images/flex-ui/teams/avatar3.png"
                                    alt=""
                                />
                                <h3 className="mb-1 text-lg text-coolGray-800 font-semibold">Elen Benitez</h3>
                                <span className="inline-block mb-6 text-lg font-medium text-green-500">CPO</span>
                                <div className="flex items-center justify-center">
                                    <a className="inline-block mr-5 text-coolGray-300 hover:text-coolGray-400" href="#">
                                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M6.63494 17.7273V9.76602H9.3583L9.76688 6.66246H6.63494V4.68128C6.63494 3.78301 6.88821 3.17085 8.20297 3.17085L9.87712 3.17017V0.394238C9.5876 0.357335 8.59378 0.272728 7.43708 0.272728C5.0217 0.272728 3.3681 1.71881 3.3681 4.37391V6.66246H0.636475V9.76602H3.3681V17.7273H6.63494Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </a>
                                    <a className="inline-block mr-5 text-coolGray-300 hover:text-coolGray-400" href="#">
                                        <svg width="19" height="16" viewBox="0 0 19 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M18.8181 2.14597C18.1356 2.44842 17.4032 2.65355 16.6336 2.74512C17.4194 2.27461 18.0208 1.5283 18.3059 0.641757C17.5689 1.07748 16.7553 1.39388 15.8885 1.56539C15.1943 0.824879 14.2069 0.363636 13.1118 0.363636C11.0108 0.363636 9.30722 2.06718 9.30722 4.16706C9.30722 4.46488 9.34083 4.75576 9.40574 5.03391C6.24434 4.87512 3.44104 3.36048 1.56483 1.05894C1.23686 1.61985 1.05028 2.27342 1.05028 2.97109C1.05028 4.29106 1.72243 5.45573 2.74225 6.13712C2.11877 6.11627 1.53237 5.94476 1.01901 5.65967V5.70718C1.01901 7.54979 2.33086 9.08761 4.07031 9.43761C3.75161 9.52336 3.41555 9.57088 3.06789 9.57088C2.82222 9.57088 2.58464 9.54655 2.35171 9.50018C2.8361 11.0125 4.24067 12.1123 5.90483 12.1424C4.6034 13.1622 2.96243 13.7683 1.1801 13.7683C0.873008 13.7683 0.570523 13.7498 0.272705 13.7162C1.95655 14.7974 3.95561 15.4278 6.10416 15.4278C13.1026 15.4278 16.928 9.63115 16.928 4.60397L16.9153 4.11145C17.6627 3.57833 18.3094 2.90851 18.8181 2.14597Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </a>
                                    <a className="inline-block text-coolGray-300 hover:text-coolGray-400" href="#">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M5.6007 0.181818H14.3992C17.3874 0.181818 19.8184 2.61281 19.8182 5.60074V14.3993C19.8182 17.3872 17.3874 19.8182 14.3992 19.8182H5.6007C2.61276 19.8182 0.181885 17.3873 0.181885 14.3993V5.60074C0.181885 2.61281 2.61276 0.181818 5.6007 0.181818ZM14.3993 18.0759C16.4267 18.0759 18.0761 16.4266 18.0761 14.3993H18.076V5.60074C18.076 3.57348 16.4266 1.92405 14.3992 1.92405H5.6007C3.57343 1.92405 1.92412 3.57348 1.92412 5.60074V14.3993C1.92412 16.4266 3.57343 18.0761 5.6007 18.0759H14.3993ZM4.85721 10.0001C4.85721 7.16424 7.16425 4.85714 10.0001 4.85714C12.8359 4.85714 15.1429 7.16424 15.1429 10.0001C15.1429 12.8359 12.8359 15.1429 10.0001 15.1429C7.16425 15.1429 4.85721 12.8359 4.85721 10.0001ZM6.62805 10C6.62805 11.8593 8.14081 13.3719 10.0001 13.3719C11.8593 13.3719 13.3721 11.8593 13.3721 10C13.3721 8.14058 11.8594 6.6279 10.0001 6.6279C8.14069 6.6279 6.62805 8.14058 6.62805 10Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-10">
                            <div className="text-center max-w-xs mx-auto">
                                <img
                                    className="w-24 h-24 mx-auto mb-6 rounded-full"
                                    src="/images/flex-ui/teams/avatar4.png"
                                    alt=""
                                />
                                <h3 className="mb-1 text-lg text-coolGray-800 font-semibold">Kacy Wade</h3>
                                <span className="inline-block mb-6 text-lg font-medium text-green-500">
                                    Customer Success
                                </span>
                                <div className="flex items-center justify-center">
                                    <a className="inline-block mr-5 text-coolGray-300 hover:text-coolGray-400" href="#">
                                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M6.63494 17.7273V9.76602H9.3583L9.76688 6.66246H6.63494V4.68128C6.63494 3.78301 6.88821 3.17085 8.20297 3.17085L9.87712 3.17017V0.394238C9.5876 0.357335 8.59378 0.272728 7.43708 0.272728C5.0217 0.272728 3.3681 1.71881 3.3681 4.37391V6.66246H0.636475V9.76602H3.3681V17.7273H6.63494Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </a>
                                    <a className="inline-block mr-5 text-coolGray-300 hover:text-coolGray-400" href="#">
                                        <svg width="19" height="16" viewBox="0 0 19 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M18.8181 2.14597C18.1356 2.44842 17.4032 2.65355 16.6336 2.74512C17.4194 2.27461 18.0208 1.5283 18.3059 0.641757C17.5689 1.07748 16.7553 1.39388 15.8885 1.56539C15.1943 0.824879 14.2069 0.363636 13.1118 0.363636C11.0108 0.363636 9.30722 2.06718 9.30722 4.16706C9.30722 4.46488 9.34083 4.75576 9.40574 5.03391C6.24434 4.87512 3.44104 3.36048 1.56483 1.05894C1.23686 1.61985 1.05028 2.27342 1.05028 2.97109C1.05028 4.29106 1.72243 5.45573 2.74225 6.13712C2.11877 6.11627 1.53237 5.94476 1.01901 5.65967V5.70718C1.01901 7.54979 2.33086 9.08761 4.07031 9.43761C3.75161 9.52336 3.41555 9.57088 3.06789 9.57088C2.82222 9.57088 2.58464 9.54655 2.35171 9.50018C2.8361 11.0125 4.24067 12.1123 5.90483 12.1424C4.6034 13.1622 2.96243 13.7683 1.1801 13.7683C0.873008 13.7683 0.570523 13.7498 0.272705 13.7162C1.95655 14.7974 3.95561 15.4278 6.10416 15.4278C13.1026 15.4278 16.928 9.63115 16.928 4.60397L16.9153 4.11145C17.6627 3.57833 18.3094 2.90851 18.8181 2.14597Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </a>
                                    <a className="inline-block text-coolGray-300 hover:text-coolGray-400" href="#">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M5.6007 0.181818H14.3992C17.3874 0.181818 19.8184 2.61281 19.8182 5.60074V14.3993C19.8182 17.3872 17.3874 19.8182 14.3992 19.8182H5.6007C2.61276 19.8182 0.181885 17.3873 0.181885 14.3993V5.60074C0.181885 2.61281 2.61276 0.181818 5.6007 0.181818ZM14.3993 18.0759C16.4267 18.0759 18.0761 16.4266 18.0761 14.3993H18.076V5.60074C18.076 3.57348 16.4266 1.92405 14.3992 1.92405H5.6007C3.57343 1.92405 1.92412 3.57348 1.92412 5.60074V14.3993C1.92412 16.4266 3.57343 18.0761 5.6007 18.0759H14.3993ZM4.85721 10.0001C4.85721 7.16424 7.16425 4.85714 10.0001 4.85714C12.8359 4.85714 15.1429 7.16424 15.1429 10.0001C15.1429 12.8359 12.8359 15.1429 10.0001 15.1429C7.16425 15.1429 4.85721 12.8359 4.85721 10.0001ZM6.62805 10C6.62805 11.8593 8.14081 13.3719 10.0001 13.3719C11.8593 13.3719 13.3721 11.8593 13.3721 10C13.3721 8.14058 11.8594 6.6279 10.0001 6.6279C8.14069 6.6279 6.62805 8.14058 6.62805 10Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-10 xl:mb-0">
                            <div className="text-center max-w-xs mx-auto">
                                <img
                                    className="w-24 h-24 mx-auto mb-6 rounded-full"
                                    src="/images/flex-ui/teams/avatar5.png"
                                    alt=""
                                />
                                <h3 className="mb-1 text-lg text-coolGray-800 font-semibold">Alya Levine</h3>
                                <span
                                    className="inline-block mb-6 text-lg font-medium text-green-500">Backend Developer</span>
                                <div className="flex items-center justify-center">
                                    <a className="inline-block mr-5 text-coolGray-300 hover:text-coolGray-400"
                                       href="#">
                                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M6.63494 17.7273V9.76602H9.3583L9.76688 6.66246H6.63494V4.68128C6.63494 3.78301 6.88821 3.17085 8.20297 3.17085L9.87712 3.17017V0.394238C9.5876 0.357335 8.59378 0.272728 7.43708 0.272728C5.0217 0.272728 3.3681 1.71881 3.3681 4.37391V6.66246H0.636475V9.76602H3.3681V17.7273H6.63494Z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </a>
                                    <a className="inline-block mr-5 text-coolGray-300 hover:text-coolGray-400"
                                       href="#">
                                        <svg width="19" height="16" viewBox="0 0 19 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M18.8181 2.14597C18.1356 2.44842 17.4032 2.65355 16.6336 2.74512C17.4194 2.27461 18.0208 1.5283 18.3059 0.641757C17.5689 1.07748 16.7553 1.39388 15.8885 1.56539C15.1943 0.824879 14.2069 0.363636 13.1118 0.363636C11.0108 0.363636 9.30722 2.06718 9.30722 4.16706C9.30722 4.46488 9.34083 4.75576 9.40574 5.03391C6.24434 4.87512 3.44104 3.36048 1.56483 1.05894C1.23686 1.61985 1.05028 2.27342 1.05028 2.97109C1.05028 4.29106 1.72243 5.45573 2.74225 6.13712C2.11877 6.11627 1.53237 5.94476 1.01901 5.65967V5.70718C1.01901 7.54979 2.33086 9.08761 4.07031 9.43761C3.75161 9.52336 3.41555 9.57088 3.06789 9.57088C2.82222 9.57088 2.58464 9.54655 2.35171 9.50018C2.8361 11.0125 4.24067 12.1123 5.90483 12.1424C4.6034 13.1622 2.96243 13.7683 1.1801 13.7683C0.873008 13.7683 0.570523 13.7498 0.272705 13.7162C1.95655 14.7974 3.95561 15.4278 6.10416 15.4278C13.1026 15.4278 16.928 9.63115 16.928 4.60397L16.9153 4.11145C17.6627 3.57833 18.3094 2.90851 18.8181 2.14597Z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </a>
                                    <a className="inline-block text-coolGray-300 hover:text-coolGray-400" href="#">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M5.6007 0.181818H14.3992C17.3874 0.181818 19.8184 2.61281 19.8182 5.60074V14.3993C19.8182 17.3872 17.3874 19.8182 14.3992 19.8182H5.6007C2.61276 19.8182 0.181885 17.3873 0.181885 14.3993V5.60074C0.181885 2.61281 2.61276 0.181818 5.6007 0.181818ZM14.3993 18.0759C16.4267 18.0759 18.0761 16.4266 18.0761 14.3993H18.076V5.60074C18.076 3.57348 16.4266 1.92405 14.3992 1.92405H5.6007C3.57343 1.92405 1.92412 3.57348 1.92412 5.60074V14.3993C1.92412 16.4266 3.57343 18.0761 5.6007 18.0759H14.3993ZM4.85721 10.0001C4.85721 7.16424 7.16425 4.85714 10.0001 4.85714C12.8359 4.85714 15.1429 7.16424 15.1429 10.0001C15.1429 12.8359 12.8359 15.1429 10.0001 15.1429C7.16425 15.1429 4.85721 12.8359 4.85721 10.0001ZM6.62805 10C6.62805 11.8593 8.14081 13.3719 10.0001 13.3719C11.8593 13.3719 13.3721 11.8593 13.3721 10C13.3721 8.14058 11.8594 6.6279 10.0001 6.6279C8.14069 6.6279 6.62805 8.14058 6.62805 10Z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-10 xl:mb-0">
                            <div className="text-center max-w-xs mx-auto">
                                <img className="w-24 h-24 mx-auto mb-6 rounded-full"
                                     src="/images/flex-ui/teams/avatar6.png" alt=""/>
                                <h3 className="mb-1 text-lg text-coolGray-800 font-semibold">Rosemary Hernandez</h3>
                                <span
                                    className="inline-block mb-6 text-lg font-medium text-green-500">IOS Developer</span>
                                <div className="flex items-center justify-center">
                                    <a className="inline-block mr-5 text-coolGray-300 hover:text-coolGray-400"
                                       href="#">
                                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M6.63494 17.7273V9.76602H9.3583L9.76688 6.66246H6.63494V4.68128C6.63494 3.78301 6.88821 3.17085 8.20297 3.17085L9.87712 3.17017V0.394238C9.5876 0.357335 8.59378 0.272728 7.43708 0.272728C5.0217 0.272728 3.3681 1.71881 3.3681 4.37391V6.66246H0.636475V9.76602H3.3681V17.7273H6.63494Z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </a>
                                    <a className="inline-block mr-5 text-coolGray-300 hover:text-coolGray-400"
                                       href="#">
                                        <svg width="19" height="16" viewBox="0 0 19 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M18.8181 2.14597C18.1356 2.44842 17.4032 2.65355 16.6336 2.74512C17.4194 2.27461 18.0208 1.5283 18.3059 0.641757C17.5689 1.07748 16.7553 1.39388 15.8885 1.56539C15.1943 0.824879 14.2069 0.363636 13.1118 0.363636C11.0108 0.363636 9.30722 2.06718 9.30722 4.16706C9.30722 4.46488 9.34083 4.75576 9.40574 5.03391C6.24434 4.87512 3.44104 3.36048 1.56483 1.05894C1.23686 1.61985 1.05028 2.27342 1.05028 2.97109C1.05028 4.29106 1.72243 5.45573 2.74225 6.13712C2.11877 6.11627 1.53237 5.94476 1.01901 5.65967V5.70718C1.01901 7.54979 2.33086 9.08761 4.07031 9.43761C3.75161 9.52336 3.41555 9.57088 3.06789 9.57088C2.82222 9.57088 2.58464 9.54655 2.35171 9.50018C2.8361 11.0125 4.24067 12.1123 5.90483 12.1424C4.6034 13.1622 2.96243 13.7683 1.1801 13.7683C0.873008 13.7683 0.570523 13.7498 0.272705 13.7162C1.95655 14.7974 3.95561 15.4278 6.10416 15.4278C13.1026 15.4278 16.928 9.63115 16.928 4.60397L16.9153 4.11145C17.6627 3.57833 18.3094 2.90851 18.8181 2.14597Z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </a>
                                    <a className="inline-block text-coolGray-300 hover:text-coolGray-400" href="#">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M5.6007 0.181818H14.3992C17.3874 0.181818 19.8184 2.61281 19.8182 5.60074V14.3993C19.8182 17.3872 17.3874 19.8182 14.3992 19.8182H5.6007C2.61276 19.8182 0.181885 17.3873 0.181885 14.3993V5.60074C0.181885 2.61281 2.61276 0.181818 5.6007 0.181818ZM14.3993 18.0759C16.4267 18.0759 18.0761 16.4266 18.0761 14.3993H18.076V5.60074C18.076 3.57348 16.4266 1.92405 14.3992 1.92405H5.6007C3.57343 1.92405 1.92412 3.57348 1.92412 5.60074V14.3993C1.92412 16.4266 3.57343 18.0761 5.6007 18.0759H14.3993ZM4.85721 10.0001C4.85721 7.16424 7.16425 4.85714 10.0001 4.85714C12.8359 4.85714 15.1429 7.16424 15.1429 10.0001C15.1429 12.8359 12.8359 15.1429 10.0001 15.1429C7.16425 15.1429 4.85721 12.8359 4.85721 10.0001ZM6.62805 10C6.62805 11.8593 8.14081 13.3719 10.0001 13.3719C11.8593 13.3719 13.3721 11.8593 13.3721 10C13.3721 8.14058 11.8594 6.6279 10.0001 6.6279C8.14069 6.6279 6.62805 8.14058 6.62805 10Z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-10 md:mb-0">
                            <div className="text-center max-w-xs mx-auto">
                                <img className="w-24 h-24 mx-auto mb-6 rounded-full"
                                     src="/images/flex-ui/teams/avatar7.png" alt=""/>
                                <h3 className="mb-1 text-lg text-coolGray-800 font-semibold">Theo Morales</h3>
                                <span className="inline-block mb-6 text-lg font-medium text-green-500">Frontend Developer</span>
                                <div className="flex items-center justify-center">
                                    <a className="inline-block mr-4 hover:opacity-80" href="#">
                                        <img src="/images/flex-ui/brands/facebook.svg" alt=""/>
                                    </a>
                                    <a className="inline-block mr-4 hover:opacity-80" href="#">
                                        <img src="/images/flex-ui/brands/twitter.svg" alt=""/>
                                    </a>
                                    <a className="inline-block hover:opacity-80" href="#">
                                        <img src="/images/flex-ui/brands/instagram.svg" alt=""/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4">
                            <div className="text-center max-w-xs mx-auto">
                                <img className="w-24 h-24 mx-auto mb-6 rounded-full"
                                     src="/images/flex-ui/teams/avatar8.png" alt=""/>
                                <h3 className="mb-1 text-lg text-coolGray-800 font-semibold">Ishmael Dunlop</h3>
                                <span
                                    className="inline-block mb-6 text-lg font-medium text-green-500">Product Designer</span>
                                <div className="flex items-center justify-center">
                                    <a className="inline-block mr-4 hover:opacity-80" href="#">
                                        <img src="/images/flex-ui/brands/facebook.svg" alt=""/>
                                    </a>
                                    <a className="inline-block mr-4 hover:opacity-80" href="#">
                                        <img src="/images/flex-ui/brands/twitter.svg" alt=""/>
                                    </a>
                                    <a className="inline-block hover:opacity-80" href="#">
                                        <img src="/images/flex-ui/brands/instagram.svg" alt=""/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            */}
        </>
    )
}
