import React from "react";
import { Outlet } from "react-router-dom";

import {HeaderNav} from "./nav/header";
import {Footer} from "./footer";

export const Layout = function() {
    return (
        <div className="app">
            <div className="bg-tarnsparent">
                <HeaderNav/>
            </div>
            <Outlet/>
            <Footer/>
        </div>
    )
}
