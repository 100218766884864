import { Route, Routes } from "react-router-dom";

import { Layout } from "./components/layout";

import { DocsPage } from "./pages/docs";
import { HomePage } from "./pages/home";
import { LoginPage } from "./pages/login";
import { SignupPage } from "./pages/signup";

import "./App.css";
import { ScrollToTop } from "./components/scroll-to-top";
import { JungleBusApp } from "./JungleBusApp";
import { BrandingPage } from "./pages/branding";
import { NotFoundPage } from "./pages/not-found";

function App() {
    return (
        <ScrollToTop>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<HomePage />} />
                    <Route path="branding" element={<BrandingPage />} />
                    <Route path="docs" element={<DocsPage />} />
                    <Route path="login" element={<LoginPage />} />
                    <Route path="signup" element={<SignupPage />} />
                    <Route path="product" element={<HomePage />} />
                    <Route path="features" element={<HomePage />} />
                    <Route path="resources" element={<HomePage />} />
                    <Route path="pricing" element={<HomePage />} />
                    <Route path="forgot-password" element={<LoginPage />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Route>
                <Route path="/junglebus/*" element={<JungleBusApp />} />
            </Routes>
        </ScrollToTop>
    );
}

export default App;
