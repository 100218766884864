import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import js from "react-syntax-highlighter/dist/esm/languages/hljs/javascript";
import github from "react-syntax-highlighter/dist/esm/styles/hljs/github";

import moment from "moment";
import { Alert } from "../../../components/alert";
import { ChartLine } from "../../../components/charts/line";
import { AuthContext } from "../../../context/auth";
import { Subscription } from "./index";

SyntaxHighlighter.registerLanguage("javascript", js);

type SubscriptionQuery = {
    addresses: string[];
    input_types: string[];
    output_types: string[];
    contexts: string[];
    sub_contexts: string[];
    data: string[];
};

export const DashboardSubscriptionsEditPage = function () {
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();
    let params = useParams();
    const subscriptionId = params?.id;

    const [subscription, setSubscription] = useState<Subscription>(
        {} as Subscription
    );
    const [query, setQuery] = useState<SubscriptionQuery>(
        {} as SubscriptionQuery
    );
    const [stats, setStats] = useState<any | null>(null);

    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (subscriptionId) {
            setLoading(true);
            fetch(`/v1/subscription/get?id=${subscriptionId}`, {
                headers: {
                    token,
                },
            })
                .then(async (response) => {
                    if (response.status === 200) {
                        const s: Subscription = await response.json();
                        setSubscription(s);
                        setQuery(JSON.parse(s.query));
                    } else {
                        setSubscription({} as Subscription);
                    }
                    setLoading(false);
                })
                .catch((e) => {
                    setError(e.message);
                    setLoading(false);
                });
        }
    }, [subscriptionId]);

    const processQuery = function (queryObject: SubscriptionQuery) {
        Object.keys(queryObject).forEach((key) => {
            // @ts-ignore
            if (queryObject[key].length === 0 || queryObject[key][0] === "") {
                // @ts-ignore
                delete queryObject[key];
            }
        });

        return JSON.stringify(queryObject);
    };

    useEffect(() => {
        (async () => {
            const response = await fetch(
                `/v1/subscription/stats?id=${subscriptionId}`,
                {
                    headers: {
                        token,
                    },
                }
            );
            if (response.status === 200) {
                const us: any = await response.json();
                setStats(us);
            } else {
                setStats(null);
            }
        })();
    }, [subscriptionId]);

    const chartLabels = useMemo(() => {
        const labels = [];
        const dates = [];
        for (let i = 0; i < 30; i++) {
            labels.push(moment().subtract(i, "days").format("MM-DD"));
            dates.push(moment().subtract(i, "days").format("YYYYMMDD"));
        }
        return {
            labels: labels.reverse(),
            dates: dates.reverse(),
        };
    }, []);

    const chartData = useMemo(() => {
        if (stats && stats.length > 0) {
            const data: any = [];
            chartLabels.dates.forEach((label) => {
                const stat = stats.find((s: any) => s.date === label);
                data.push(stat?.txs_read || 0 + (stat?.mempool_read || 0));
            });

            return [
                {
                    label: "Transactions",
                    data,
                },
            ];
        }
        return [];
    }, [chartLabels, stats]);

    const saveSubscription = useCallback(
        (token: string) => {
            if (!subscription.name) {
                setError("Name field is mandatory");
                return;
            }

            setLoading(true);
            const saveDoc = Object.assign({ ...subscription });
            if (!subscriptionId) {
                saveDoc.id = "new";
            }
            saveDoc.query = processQuery(query);
            fetch(`/v1/subscription/save`, {
                method: "POST",
                headers: {
                    token,
                    "content-type": "application/json",
                },
                body: JSON.stringify(saveDoc),
            })
                .then(async (response) => {
                    if (response.status === 200) {
                        const s: Subscription = await response.json();
                        setSubscription(s);
                        setQuery(JSON.parse(s.query));
                        navigate(`/junglebus/subscriptions/${s.id}`);
                    } else {
                        setSubscription({} as Subscription);
                    }
                    setLoading(false);
                })
                .catch((e) => {
                    setError(e.message);
                    setLoading(false);
                });
        },
        [subscription, query, subscriptionId, navigate]
    );

    return (
        <section className="bg-coolGray-50 py-4">
            <div className="container px-4 mx-auto">
                {loading ? (
                    <>Loading ...</>
                ) : (
                    <div className="p-6 h-full border border-coolGray-100 overflow-hidden bg-white rounded-md shadow-dashboard">
                        <div className="pb-6 border-b border-coolGray-100">
                            <div className="flex flex-wrap items-center justify-between -m-2">
                                <div className="w-full md:w-auto p-2">
                                    <h2 className="text-coolGray-900 text-lg font-semibold">
                                        {subscriptionId
                                            ? "Edit subscription"
                                            : "Create new subscription"}
                                    </h2>
                                </div>
                                <div className="w-full md:w-auto p-2">
                                    <div className="flex flex-wrap justify-between -m-1.5">
                                        <div className="w-full md:w-auto p-1.5">
                                            <button
                                                className="flex flex-wrap justify-center w-full px-4 py-2 font-medium text-sm text-coolGray-500 hover:text-coolGray-600 border border-coolGray-200 hover:border-coolGray-300 bg-white rounded-md shadow-button"
                                                onClick={() =>
                                                    navigate(
                                                        "/junglebus/subscriptions"
                                                    )
                                                }
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        <div className="w-full md:w-auto p-1.5">
                                            <button
                                                className="flex flex-wrap justify-center w-full px-4 py-2 bg-green-500 hover:bg-green-600 font-medium text-sm text-white border border-green-500 rounded-md shadow-button"
                                                onClick={() =>
                                                    saveSubscription(token)
                                                }
                                            >
                                                {subscriptionId
                                                    ? "Update subscription"
                                                    : "Save subscription"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {error && (
                            <Alert
                                message={error}
                                onClose={() => setError("")}
                            />
                        )}
                        <div className="py-6 border-b border-coolGray-100">
                            <div className="w-full md:w-9/12">
                                <div className="flex flex-wrap -m-3">
                                    <div className="w-full md:w-1/3 p-3">
                                        <p className="text-sm text-coolGray-800 font-semibold">
                                            ID
                                        </p>
                                    </div>
                                    <div className="w-full md:w-2/3 p-3">
                                        {subscription.id}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="py-6 border-b border-coolGray-100">
                            <div className="w-full md:w-9/12">
                                <div className="flex flex-wrap -m-3">
                                    <div className="w-full md:w-1/3 p-3">
                                        <p className="text-sm text-coolGray-800 font-semibold">
                                            Name
                                        </p>
                                    </div>
                                    <div className="w-full md:w-2/3 p-3">
                                        <input
                                            className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none focus:border-green-500 border border-coolGray-200 rounded-lg shadow-input"
                                            type="text"
                                            placeholder="Give your subscription a name"
                                            value={subscription.name}
                                            onChange={(e) =>
                                                setSubscription({
                                                    ...subscription,
                                                    name: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="py-6 border-b border-coolGray-100">
                            <div className="w-full md:w-9/12">
                                <div className="flex flex-wrap -m-3">
                                    <div className="w-full md:w-1/3 p-3">
                                        <p className="text-sm text-coolGray-800 font-semibold">
                                            Description
                                        </p>
                                    </div>
                                    <div className="w-full md:flex-1 p-3">
                                        <input
                                            className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none focus:border-green-500 border border-coolGray-200 rounded-lg shadow-input"
                                            type="text"
                                            placeholder="Describe your subscription"
                                            value={subscription.description}
                                            onChange={(e) =>
                                                setSubscription({
                                                    ...subscription,
                                                    description: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!!(stats && stats.length) && (
                            <div className="my-2 pt-4">
                                <ChartLine
                                    datasets={chartData}
                                    labels={chartLabels.labels}
                                    height={240}
                                />
                            </div>
                        )}
                        <div className="py-6 border-b border-coolGray-100">
                            <div className="flex flex-wrap items-center justify-between -m-2">
                                <div className="w-full md:w-auto p-2">
                                    <h2 className="text-coolGray-900 text-lg font-semibold">
                                        Subscription definition
                                    </h2>
                                    <p className="text-xs text-coolGray-500 font-medium">
                                        If multiple values are selected, they
                                        will be queried with an 'AND' query, and
                                        therefore all the values have to be
                                        present in the transaction to match.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="py-6 border-b border-coolGray-100">
                            <div className="w-full md:w-9/12">
                                <div className="flex flex-wrap -m-3">
                                    <div className="w-full md:w-1/3 p-3">
                                        <p className="text-sm text-coolGray-800 font-semibold">
                                            Addresses
                                        </p>
                                        <p className="text-xs text-coolGray-500 font-medium">
                                            Comma separated list of Bitcoin
                                            addresses
                                        </p>
                                    </div>
                                    <div className="w-full md:flex-1 p-3">
                                        <input
                                            className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none focus:border-green-500 border border-coolGray-200 rounded-lg shadow-input"
                                            type="text"
                                            value={query.addresses?.join(",")}
                                            onChange={(e) =>
                                                setQuery({
                                                    ...query,
                                                    addresses:
                                                        e.target.value.split(
                                                            ","
                                                        ),
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="py-6 border-b border-coolGray-100">
                            <div className="w-full md:w-9/12">
                                <div className="flex flex-wrap -m-3">
                                    <div className="w-full md:w-1/3 p-3">
                                        <p className="text-sm text-coolGray-800 font-semibold">
                                            Input types
                                        </p>
                                        <p className="text-xs text-coolGray-500 font-medium">
                                            Comma separated list
                                        </p>
                                    </div>
                                    <div className="w-full md:flex-1 p-3">
                                        <input
                                            className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none focus:border-green-500 border border-coolGray-200 rounded-lg shadow-input"
                                            type="text"
                                            value={query.input_types?.join(
                                                ","
                                            )}
                                            onChange={(e) =>
                                                setQuery({
                                                    ...query,
                                                    input_types:
                                                        e.target.value.split(
                                                            ","
                                                        ),
                                                })
                                            }
                                        />
                                        <span className="text-xs text-coolGray-500">
                                            <i>
                                                ordlock, sigil
                                            </i>{" "}
                                            - see the{" "}
                                            <a
                                                href="https://junglebus.gorillapool.io/docs"
                                                target="_blank"
                                            >
                                                documentation
                                            </a>{" "}
                                            for a full list
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="py-6 border-b border-coolGray-100">
                            <div className="w-full md:w-9/12">
                                <div className="flex flex-wrap -m-3">
                                    <div className="w-full md:w-1/3 p-3">
                                        <p className="text-sm text-coolGray-800 font-semibold">
                                            Output types
                                        </p>
                                        <p className="text-xs text-coolGray-500 font-medium">
                                            Comma separated list
                                        </p>
                                    </div>
                                    <div className="w-full md:flex-1 p-3">
                                        <input
                                            className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none focus:border-green-500 border border-coolGray-200 rounded-lg shadow-input"
                                            type="text"
                                            value={query.output_types?.join(
                                                ","
                                            )}
                                            onChange={(e) =>
                                                setQuery({
                                                    ...query,
                                                    output_types:
                                                        e.target.value.split(
                                                            ","
                                                        ),
                                                })
                                            }
                                        />
                                        <span className="text-xs text-coolGray-500">
                                            <i>
                                                examples: aip, bap,
                                                bitcom,pubkeyhash, map, ord,
                                                run, token_stas
                                            </i>{" "}
                                            - see the{" "}
                                            <a
                                                href="https://junglebus.gorillapool.io/docs"
                                                target="_blank"
                                            >
                                                documentation
                                            </a>{" "}
                                            for a full list
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="py-6 border-b border-coolGray-100">
                            <div className="w-full md:w-9/12">
                                <div className="flex flex-wrap -m-3">
                                    <div className="w-full md:w-1/3 p-3">
                                        <p className="text-sm text-coolGray-800 font-semibold">
                                            Contexts
                                        </p>
                                        <p className="text-xs text-coolGray-500 font-medium">
                                            Comma separated list
                                        </p>
                                    </div>
                                    <div className="w-full md:flex-1 p-3">
                                        <input
                                            className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none focus:border-green-500 border border-coolGray-200 rounded-lg shadow-input"
                                            type="text"
                                            value={query.contexts?.join(",")}
                                            onChange={(e) =>
                                                setQuery({
                                                    ...query,
                                                    contexts:
                                                        e.target.value.split(
                                                            ","
                                                        ),
                                                })
                                            }
                                        />
                                        <span className="text-xs text-coolGray-500">
                                            <i>
                                                Contexts depend on transaction
                                                output type
                                            </i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="py-6 border-b border-coolGray-100">
                            <div className="w-full md:w-9/12">
                                <div className="flex flex-wrap -m-3">
                                    <div className="w-full md:w-1/3 p-3">
                                        <p className="text-sm text-coolGray-800 font-semibold">
                                            Sub contexts
                                        </p>
                                        <p className="text-xs text-coolGray-500 font-medium">
                                            Comma separated list
                                        </p>
                                    </div>
                                    <div className="w-full md:flex-1 p-3">
                                        <input
                                            className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none focus:border-green-500 border border-coolGray-200 rounded-lg shadow-input"
                                            type="text"
                                            value={query.sub_contexts?.join(
                                                ","
                                            )}
                                            onChange={(e) =>
                                                setQuery({
                                                    ...query,
                                                    sub_contexts:
                                                        e.target.value.split(
                                                            ","
                                                        ),
                                                })
                                            }
                                        />
                                        <span className="text-xs text-coolGray-500">
                                            <i>
                                                Sub contexts depend on
                                                transaction output type
                                            </i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="py-6 border-b border-coolGray-100">
                            <div className="w-full md:w-9/12">
                                <div className="flex flex-wrap -m-3">
                                    <div className="w-full md:w-1/3 p-3">
                                        <p className="text-sm text-coolGray-800 font-semibold">
                                            Data keys
                                        </p>
                                        <p className="text-xs text-coolGray-500 font-medium">
                                            Comma separated list
                                        </p>
                                    </div>
                                    <div className="w-full md:flex-1 p-3">
                                        <input
                                            className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none focus:border-green-500 border border-coolGray-200 rounded-lg shadow-input"
                                            type="text"
                                            value={query.data?.join(",")}
                                            onChange={(e) =>
                                                setQuery({
                                                    ...query,
                                                    data: e.target.value.split(
                                                        ","
                                                    ),
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!!subscriptionId && (
                            <div className="py-6 border-b border-coolGray-100">
                                <div className="w-full">
                                    <div className="flex flex-wrap -m-3">
                                        <div className="w-full p-3">
                                            <p className="text-sm text-coolGray-800">
                                                <b>
                                                    You can query your
                                                    subscription in the
                                                    JungleBus JavaScript client
                                                    in the following way:
                                                </b>
                                                <br />
                                            </p>
                                            <SyntaxHighlighter
                                                language="javascript"
                                                style={github}
                                            >
                                                {exampleJS(
                                                    subscriptionId,
                                                    "720000"
                                                )}
                                            </SyntaxHighlighter>
                                            <br />
                                            <p>
                                                <b>--- OR ---</b>
                                            </p>
                                            <br />
                                            <p className="text-sm text-coolGray-800">
                                                <b>
                                                    You can query your
                                                    subscription in the
                                                    JungleBus Centrifuge client
                                                    on the following channel:
                                                </b>
                                                <br />
                                                query:{subscriptionId}
                                                :&lt;block&gt;
                                                <br />
                                                <i>AND</i>
                                                <br />
                                                query:{subscriptionId}:mempool
                                                <br />
                                                query:{subscriptionId}:control
                                                <br />
                                                <br />
                                            </p>
                                            <p className="text-xs text-coolGray-500 font-medium">
                                                Example:{" "}
                                                <b>
                                                    query:{subscriptionId}
                                                    :700200
                                                </b>
                                            </p>
                                            <br />
                                            <p className="text-xs text-coolGray-500 font-medium">
                                                This will start the transaction
                                                publisher on this subscription
                                                from block 700200 onwards
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </section>
    );
};

const exampleJS = function (subscriptionId: string, fromBlock: string) {
    return `
    import { JungleBusClient, ControlMessageStatusCode } from "@gorillapool/js-junglebus";

    const client = new JungleBusClient("junglebus.gorillapool.io", {
        useSSL: true,
        onConnected(ctx) {
            console.log("CONNECTED", ctx);
        },
        onConnecting(ctx) {
            console.log("CONNECTING", ctx);
        },
        onDisconnected(ctx) {
            console.log("DISCONNECTED", ctx);
        },
        onError(ctx) {
            console.error(ctx);
        },
    });

    const onPublish = function(tx) {
        console.log("TRANSACTION", tx);
    };
    const onStatus = function(message) {
        if (message.statusCode === ControlMessageStatusCode.BLOCK_DONE) {
          console.log("BLOCK DONE", message.block);
        } else if (message.statusCode === ControlMessageStatusCode.WAITING) {
          console.log("WAITING FOR NEW BLOCK...", message);
        } else if (message.statusCode === ControlMessageStatusCode.REORG) {
          console.log("REORG TRIGGERED", message);
        } else if (message.statusCode === ControlMessageStatusCode.ERROR) {
          console.error(message);
        }
    };
    const onError = function(err) {
        console.error(err);
    };
    const onMempool = function(tx) {
        console.log("TRANSACTION", tx);
    };

    (async () => {
        await client.Subscribe("${subscriptionId}", ${fromBlock}, onPublish, onStatus, onError, onMempool);
    })();
`;
};
