import React, { useContext, useEffect, useMemo, useState } from 'react';

import { AuthContext } from "../../context/auth";
import { formatNumber } from "../../utils/numbers";
import { ChartLine } from "../../components/charts/line";
import moment from "moment";

type JungleBusStats = {
  blocks: number
  synced: number
  last_synced: number
  transactions: number
  transactions_per_day: any
}

type UserStats = {
  subscriptions: number
}

type Stats = {
  junglebus: JungleBusStats
  user: UserStats
}

export const HomePage = function () {
  const { token } = useContext(AuthContext);

  const [stats, setStats] = useState<Stats | null>(null);

  useEffect(() => {
    (async () => {
      const response = await fetch(`/v1/user/stats`, {
        headers: {
          token,
        }
      });
      if (response.status === 200) {
        const us: Stats = await response.json();
        setStats(us);
      } else {
        setStats(null);
      }
    })();
  }, []);

  const chartLabels = useMemo(() => {
    const labels = [];
    for (let i = 0; i < 30; i++) {
      labels.push(moment().subtract(i, 'days').format('YYYY-MM-DD'));
    }
    return labels.reverse();
  }, []);

  const chartData = useMemo(() => {
    const data: any = [];
    const txPerDay = stats?.junglebus?.transactions_per_day;
    chartLabels.forEach(label => {
      data.push(txPerDay ? txPerDay[label] || 0 : 0);
    });

    return [{
      label: "Transactions",
      data,
    }];
  }, [chartLabels, stats]);

  return (
    <section className="bg-coolGray-50 py-4">
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap -m-3">
          <div className="w-full p-3">
            <div
              className="p-6 h-full border border-coolGray-100 overflow-hidden bg-white rounded-md shadow-dashboard">
              <h2 className="mb-6 text-coolGray-900 text-lg font-semibold">
                JungleBus stats
              </h2>
              <div className="flex flex-wrap justify-between -m-2 mb-2">
                <div className="w-auto p-2">
                  <div className="flex flex-wrap items-center -m-1.5">
                    <div className="w-auto p-1.5">
                      <h2 className="font-semibold text-2xl text-coolGray-900">
                        {stats?.junglebus ? formatNumber(stats.junglebus.blocks, 0) : ''}
                      </h2>
                    </div>
                  </div>
                  <p className="font-medium text-xs text-coolGray-500">Blocks</p>
                </div>
                <div className="w-auto p-2">
                  <div className="flex flex-wrap items-center -m-1.5">
                    <div className="w-auto p-1.5">
                      <h2 className="font-semibold text-2xl text-coolGray-900">
                        {stats?.junglebus ? formatNumber(100 * stats.junglebus.synced / stats.junglebus.blocks, 3) + '%' : ''}
                      </h2>
                    </div>
                  </div>
                  <p className="font-medium text-xs text-coolGray-500">Synced</p>
                </div>
                <div className="w-auto p-2">
                  <div className="flex flex-wrap items-center -m-1.5">
                    <div className="w-auto p-1.5">
                      <h2 className="font-semibold text-2xl text-coolGray-900">
                        {stats?.junglebus ? formatNumber(stats.junglebus.last_synced, 0) : ''}
                      </h2>
                    </div>
                  </div>
                  <p className="font-medium text-xs text-coolGray-500">Last synced block</p>
                </div>
                <div className="w-auto p-2">
                  <div className="flex flex-wrap items-center -m-1.5">
                    <div className="w-auto p-1.5">
                      <h2 className="font-semibold text-2xl text-coolGray-900">
                        {stats?.junglebus ? formatNumber(stats.junglebus.transactions, 0) : ''}
                      </h2>
                    </div>
                  </div>
                  <p className="font-medium text-xs text-coolGray-500">
                    Transactions
                  </p>
                </div>
              </div>
              <div className="my-2 pt-4">
                <ChartLine datasets={chartData} labels={chartLabels}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
