import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {usePrevious} from "../hooks/use-previous";
import {useLocalStorage} from "../hooks/localstorage";

export type IAuthContext = {
    authenticated: boolean;
    setAuthenticated: (isAuthenticated: boolean) => void;
    token: string;
    setToken: Function;
    logout: Function;
    user: any
};

export type User = {
    id: string
    username: string
    full_name: string
    email: string
    private_key: string // encrypted
    public_key: string
}

/* istanbul ignore next */
const noop = () => {
};

export const AuthContext = React.createContext<IAuthContext>({
    authenticated: false,
    setAuthenticated: noop,
    token: "",
    setToken: noop,
    logout: noop,
    user: null
});

export type AuthProviderProps = {
    onLogin?: () => void;
    onLogout?: () => void;
    children?: any;
};

export const AuthProvider: React.FC<AuthProviderProps> = (
    {
        onLogin,
        onLogout,
        children,
    }
) => {

    const [authenticated, setAuthenticated] = useState(false);
    const [token, _setToken] = useLocalStorage("jb.auth.token", "");
    const [user, setUser] = useState<User | null>(null);

    const previousAuthenticated = usePrevious(authenticated);

    const setToken = useCallback((token: string) => {
        setAuthenticated(true)
        _setToken(token);
    }, [setAuthenticated, _setToken]);

    const logout = useCallback(() => {
        console.log("DOING LOGOUT")
        _setToken("");
        setAuthenticated(false)
    }, [_setToken, setAuthenticated]);

    const refreshToken = useCallback(async function () {
        const response = await fetch(`/v1/user/refresh-token`, {
            headers: {
                token,
            }
        });
        if (response.status === 200) {
            const body = await response.json();
            if (body.token) {
                setToken(body.token)
            }
        } else {
            logout();
        }
    }, [token, logout, setToken]);

    const getUser = useCallback(async function () {
        if (token) {
            const response = await fetch(`/v1/user/get`, {
                headers: {
                    token,
                }
            });
            if (response.status === 200) {
                const u: User = await response.json();
                setUser(u);
            } else {
                logout();
            }
        }
    }, [token, logout]);

    useEffect(() => {
        (async () => {
            if (token) {
                setAuthenticated(true);
                await refreshToken();
            }
        })()
    }, []);

    useEffect(() => {
        if (authenticated) {
            (async () => {
                await getUser();
            })();
        }
    }, [authenticated]);

    useEffect(() => {
        if (!previousAuthenticated && authenticated) {
            onLogin && onLogin();
        }
    }, [previousAuthenticated, authenticated, onLogin]);

    useEffect(() => {
        if (previousAuthenticated && !authenticated) {
            onLogout && onLogout();
        }
    }, [previousAuthenticated, authenticated, onLogout]);

    useEffect(() => {
        const interval = setInterval(async () => {
            if (authenticated) {
                await refreshToken();
            }
        }, 60000); // refresh auth token every 60 seconds (is valid for 120 seconds)

        return () => clearInterval(interval);
    }, [token, authenticated]);

    const contextValue = useMemo(
        () => ({
            authenticated,
            setAuthenticated,
            token,
            setToken,
            logout,
            user,
        }),
        [authenticated, setAuthenticated, token, setToken, logout, user]
    );

    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
};
