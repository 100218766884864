import React, {useState} from 'react';

type Settings = {
    turned_on: boolean
    turned_on2: boolean
}

export const DashboardSettingsPage = function() {

    const [settings, setSettings] = useState<Settings>({} as Settings)

    return (
        <section className="bg-coolGray-50 py-4">
            <div className="container px-4 mx-auto">
                <div className="p-6 h-full border border-coolGray-100 overflow-hidden bg-white rounded-md shadow-dashboard">
                    <div className="pb-6 border-b border-coolGray-100">
                        <div className="flex flex-wrap items-center justify-between -m-2">
                            <div className="w-full md:w-auto p-2">
                                <h2 className="text-coolGray-900 text-lg font-semibold">
                                    Settings
                                </h2>
                                <p className="text-xs text-coolGray-500 font-medium">
                                    All your JungleBus settings in 1 place
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="py-6 border-b border-coolGray-100">
                        <div className="w-full md:w-9/12">
                            <div className="flex flex-wrap -m-3">
                                <div className="w-full md:w-1/3 p-3">
                                    <p className="text-sm text-coolGray-800 font-semibold">
                                        Subscription options:
                                    </p>
                                </div>
                                <div className="w-full md:w-2/3 p-3">
                                    <input
                                        id="setting-1"
                                        type="checkbox"
                                        checked={settings['turned_on']}
                                        onChange={() => setSettings({...settings, 'turned_on': !settings['turned_on']})}
                                    />
                                    <label htmlFor="setting-1">
                                        <span className="ml-1">Some setting that can be turned on</span>
                                    </label>
                                </div>
                            </div>
                            <div className="flex flex-wrap -m-3">
                                <div className="w-full md:w-1/3 p-3"/>
                                <div className="w-full md:w-2/3 p-3">
                                    <input
                                        id="setting-2"
                                        type="checkbox"
                                        checked={settings['turned_on2']}
                                        onChange={() => setSettings({...settings, 'turned_on2': !settings['turned_on2']})}
                                    />
                                    <label htmlFor="setting-2">
                                        <span className="ml-1">Some other setting that can be turned on</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
