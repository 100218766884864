import React, {useContext} from "react";
import {AuthContext} from "./context/auth";
import {HeaderNav} from "./components/nav/header";
import {LoginPage} from "./pages/login";
import {Footer} from "./components/footer";
import {DashboardLayout} from "./components/dashboard-layout";
import {Route, Routes} from "react-router-dom";
import {HomePage as AuthenticatedHomePage} from "./pages/authenticated/home";
import {DashboardAccountPage} from "./pages/authenticated/account";
import {DashboardSubscriptionsPage} from "./pages/authenticated/subscriptions";
import {DashboardSettingsPage} from "./pages/authenticated/settings";
import {DashboardSubscriptionsEditPage} from "./pages/authenticated/subscriptions/edit";
import {DashboardSubscriptionsTestPage} from "./pages/authenticated/subscriptions/test";

export const JungleBusApp = function () {
    const {authenticated} = useContext(AuthContext);

    if (!authenticated) {
        return (
            <div className="app">
                <div className="bg-tarnsparent">
                    <HeaderNav/>
                </div>
                <LoginPage/>
                <Footer/>
            </div>
        )
    }

    return (
        <Routes>
            <Route path="/" element={<DashboardLayout/>}>
                <Route index element={<AuthenticatedHomePage/>}/>
                <Route path="subscriptions">
                    <Route index element={<DashboardSubscriptionsPage/>}/>
                    <Route path="new" element={<DashboardSubscriptionsEditPage/>}/>
                    <Route path="test/:id" element={<DashboardSubscriptionsTestPage/>}/>
                    <Route path=":id" element={<DashboardSubscriptionsEditPage/>}/>
                </Route>
                <Route path="account" element={<DashboardAccountPage/>}/>
                <Route path="settings" element={<DashboardSettingsPage/>}/>
            </Route>
        </Routes>
    )
}
