export const BrandingPage = function () {
    return (
        <>
            <section
                className="relative py-24 md:py-40 bg-white"
                style={{
                    backgroundImage: "url('/images/flex-ui/pattern-white.svg')",
                    backgroundPosition: "center",
                }}
            >
                <div className="relative z-10 container px-4 mx-auto">
                    <div className="flex flex-wrap items-center -mx-4">
                        <div className="w-full md:w-1/2 px-4 mb-16 md:mb-0">
                            <div className="md:max-w-xl md:mx-auto text-center md:text-left">
                                Soon Tm
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
