import {
    ControlMessage,
    ControlMessageStatusCode,
    JungleBusClient,
    JungleBusSubscription,
    Transaction,
} from "@gorillapool/js-junglebus";
import { Error } from "centrifuge/build/types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/auth";
import { useQuery } from "../../../hooks/queryString";
import { Subscription } from "./index";

export const DashboardSubscriptionsTestPage = function () {
    const params = useParams();
    const query = useQuery();

    const navigate = useNavigate();
    const { token } = useContext(AuthContext);

    const [client, setClient] = useState<JungleBusClient | null>(null);
    const [subscription, setSubscription] =
        useState<JungleBusSubscription | null>(null);

    const startBlock = query.get("block");

    const [fromBlock, setFromBlock] = useState(
        startBlock ? parseInt(startBlock) : 750000
    );

    const [sub, setSub] = useState<Subscription | null>(null);
    const [includeMempool, setIncludeMempool] = useState<boolean>(false);
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [selectedTransaction, setSelectedTransaction] =
        useState<Transaction | null>(null);

    const [connected, setConnected] = useState<any>(null);
    const [error, setError] = useState<Error | null>(null);
    const [currentStatus, setCurrentStatus] = useState("Disconnected");
    const [currentBlock, setCurrentBlock] = useState(fromBlock);

    useEffect(() => {
        fetch(`/v1/subscription/get?id=${params.id}`, {
            headers: {
                token,
            },
        })
            .then(async (response) => {
                if (response.status === 200) {
                    const s: Subscription = await response.json();
                    setSub(s);
                } else {
                    setSub(null);
                }
            })
            .catch((e) => {
                setError(e.message);
            });
    }, []);

    const stopSubscription = useCallback(() => {
        if (subscription != null) {
            subscription.UnSubscribe();
            client?.Disconnect();
            setClient(null);
            setSubscription(null);
            setFromBlock(currentBlock);
        }
    }, [client, subscription, currentBlock]);

    const onPublish = useCallback(
        (tx: Transaction) => {
            setTransactions((txs) => [...txs, tx]);
        },
        [setTransactions]
    );

    useEffect(() => {
        if (transactions.length > 20) {
            stopSubscription();
        }
    }, [transactions, stopSubscription]);

    const onStatus = useCallback((message: ControlMessage) => {
        if (message.statusCode === ControlMessageStatusCode.BLOCK_DONE) {
            setCurrentBlock(message.block);
            setCurrentStatus("Running");
        } else if (message.statusCode === ControlMessageStatusCode.WAITING) {
            setCurrentStatus("Waiting...");
        } else if (message.statusCode === ControlMessageStatusCode.REORG) {
            setCurrentStatus("Reorg!");
        } else if (message.statusCode === ControlMessageStatusCode.ERROR) {
            // @ts-ignore
            setError({
                code: message.statusCode,
                message: (message as any)?.error as string,
            } as Error);
        }
    }, []);

    const onError = useCallback((message: any) => {
        setError({
            code: message.statusCode,
            message: message?.error as string,
        } as Error);
    }, []);

    const onMempool = useCallback((tx: Transaction) => {
        setTransactions((txs) => [...txs, tx]);
    }, []);

    const startSubscription = useCallback(
        async (token: string) => {
            setError(null);
            setTransactions([]);
            const client = new JungleBusClient(`${document.location.host}`, {
                protocol: "json",
                useSSL: !!document.location.protocol.match(/https|wss/),
                debug: true,
                token,
                onConnected(ctx) {
                    setConnected(ctx.data);
                    setCurrentStatus("Connected");
                },
                onConnecting(ctx) {
                    setCurrentStatus("Connecting");
                },
                onDisconnected(ctx) {
                    setConnected(null);
                    setCurrentStatus("Disconnected");
                },
                onError(ctx) {
                    setError(ctx.error);
                },
            });
            client.Connect();

            setSubscription(
                await client.Subscribe(
                    params.id as string,
                    fromBlock,
                    onPublish,
                    onStatus,
                    onError,
                    includeMempool ? onMempool : undefined
                )
            );
            setClient(client);
        },
        [
            params,
            fromBlock,
            onPublish,
            onStatus,
            onError,
            onMempool,
            includeMempool,
        ]
    );

    return (
        <section className="bg-coolGray-50 py-4">
            <div className="container px-4 mx-auto">
                <div className="p-6 h-full border border-coolGray-100 overflow-hidden bg-white rounded-md shadow-dashboard">
                    <div className="pb-6 border-b border-coolGray-100">
                        <div className="flex flex-wrap items-center justify-between -m-2">
                            <div className="p-2">
                                <h2 className="text-coolGray-900 text-lg font-semibold">
                                    Test subscription: {sub?.name || params.id}
                                </h2>
                                <p className="text-xs text-coolGray-500 font-medium">
                                    Running JungleBus subscription:{" "}
                                    <i>
                                        query:{params.id}:{fromBlock}
                                    </i>
                                </p>
                                {connected}
                                {!!error && (
                                    <h4 className="mb-4 text-md md:text-xl font-bold text-red-500">
                                        {error.code}: {error.message}
                                    </h4>
                                )}
                            </div>
                            <div className="p-2">
                                <button
                                    className="flex flex-wrap justify-center w-full px-4 py-2 font-medium text-sm text-coolGray-500 hover:text-coolGray-600 border border-coolGray-200 hover:border-coolGray-300 bg-white rounded-md shadow-button"
                                    onClick={(e) => {
                                        navigate("/junglebus/subscriptions");
                                    }}
                                >
                                    &lt; Back
                                </button>
                            </div>
                        </div>
                        <div className="w-full p-2">
                            <div className="flex flex-wrap items-center justify-between -m-2">
                                <div className="w-full md:w-auto p-2">
                                    <button
                                        className="flex flex-wrap justify-center w-full px-4 py-2 bg-green-500 hover:bg-green-600 font-medium text-sm text-white border border-green-500 rounded-md shadow-button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (client) {
                                                stopSubscription();
                                            } else {
                                                startSubscription(token);
                                            }
                                        }}
                                    >
                                        {client ? (
                                            <>Stop subscription</>
                                        ) : (
                                            <>Start subscription</>
                                        )}
                                    </button>
                                    <input
                                        id="include-mempool"
                                        type="checkbox"
                                        checked={includeMempool}
                                        disabled={!!client}
                                        onChange={() =>
                                            setIncludeMempool(!includeMempool)
                                        }
                                    />
                                    <label htmlFor="include-mempool">
                                        <span className="ml-1">
                                            Include mempool transactions
                                        </span>
                                    </label>
                                </div>
                                <div className="w-full md:w-auto p-2">
                                    Status: {currentStatus}{" "}
                                    {!!subscription && (
                                        <>
                                            <br />
                                            Block: {currentBlock}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-6 border-b border-coolGray-100">
                        <div className="w-full">
                            <div className="overflow-x-auto">
                                <table className="w-full">
                                    <tbody>
                                        <tr className="whitespace-nowrap h-11 bg-coolGray-50 bg-opacity-80">
                                            <th className="px-4 font-semibold text-xs text-coolGray-500 uppercase text-left rounded-l-md">
                                                Transaction ID
                                            </th>
                                            <th className="whitespace-nowrap px-4 font-semibold text-xs text-coolGray-500 uppercase text-center">
                                                Block
                                            </th>
                                            <th className="whitespace-nowrap px-4 font-semibold text-xs text-coolGray-500 uppercase text-center">
                                                Number
                                            </th>
                                        </tr>
                                        {transactions?.map((tx) => {
                                            return (
                                                <React.Fragment
                                                    key={`transaction_${tx.id}`}
                                                >
                                                    <tr
                                                        className={`h-18 border-b border-coolGray-100 bg-white hover:bg-gray-100 cursor-pointer ${
                                                            selectedTransaction?.id ===
                                                            tx.id
                                                                ? " bg-gray-300"
                                                                : ""
                                                        }`}
                                                        onClick={() =>
                                                            setSelectedTransaction(
                                                                selectedTransaction?.id ===
                                                                    tx.id
                                                                    ? null
                                                                    : tx
                                                            )
                                                        }
                                                    >
                                                        <td className="whitespace-nowrap px-4 text-left">
                                                            {tx.id}
                                                        </td>
                                                        <td className="whitespace-nowrap px-4 text-sm font-medium text-coolGray-800 text-center">
                                                            {tx.block_height}
                                                        </td>
                                                        <td className="whitespace-nowrap px-4 text-sm font-medium text-coolGray-800 text-center">
                                                            {tx.block_index ||
                                                                "0"}
                                                        </td>
                                                    </tr>
                                                    {selectedTransaction?.id ===
                                                        tx.id && (
                                                        <tr>
                                                            <td
                                                                colSpan={3}
                                                                className="p-4"
                                                            >
                                                                <DashboardSubscriptionsTestDetails
                                                                    tx={tx}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

type IDashboardSubscriptionsTestDetails = {
    tx: Transaction;
};

const DashboardSubscriptionsTestDetails = function ({
    tx,
}: IDashboardSubscriptionsTestDetails) {
    const { token } = useContext(AuthContext);

    const [txDetails, setTxDetails] = useState<any>(null);

    useEffect(() => {
        fetch(`/v1/transaction/get/${tx.id}`, {
            method: "GET",
            headers: {
                token,
            },
        })
            .then(async (response) => {
                if (response.status === 200) {
                    const s = await response.json();
                    setTxDetails(s);
                } else {
                    setTxDetails(null);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }, [tx]);

    return (
        <pre>
            <b>Subscription message:</b>
            <br />
            {JSON.stringify(tx, null, 4)}
            <br />
            {txDetails && (
                <>
                    <b>{`JungleBus ${
                        tx.block_height ? "database" : "mempool"
                    } document:`}</b>
                    <br />
                    {JSON.stringify(txDetails, null, 4)}
                </>
            )}
        </pre>
    );
};
