import { FormEvent, useContext, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/auth";

export const LoginPage = function () {
    const { setToken } = useContext(AuthContext);
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const canLogin = useMemo(() => {
        return !!(username && password);
    }, [username, password]);

    const submitHandler = function (e: FormEvent) {
        e.preventDefault();

        setError("");

        if (!username) {
            alert("Please fill in a username");
            return;
        }
        if (!password) {
            alert("Please fill in a password");
            return;
        }

        setLoading(true);
        fetch(`/v1/user/login`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({
                username,
                password,
            }),
        })
            .then(async (response) => {
                if (response.status !== 200) {
                    setError("Failed logging in");
                    setLoading(false);
                    return;
                }

                const body = await response.json();
                setToken(body.token);
                navigate("/junglebus");
            })
            .catch((e) => {
                setError("Failed logging in: " + e.message);
                setLoading(false);
            });
    };

    // console.log({username, password, rememberMe, canLogin})

    return (
        <section
            className="relative bg-white overflow-hidden"
            style={{
                backgroundImage: "url('/images/flex-ui/pattern-white.svg')",
                backgroundPosition: "center",
            }}
        >
            <div className="py-20 md:py-28">
                <div className="container px-4 mx-auto">
                    <div className="flex flex-wrap xl:items-center -mx-4">
                        <div className="w-full md:w-3/5 lg:w-1/2">
                            <div className="max-w-sm mx-auto">
                                <div className="mb-6 text-center">
                                    <Link
                                        to={"/signup"}
                                        className="inline-block mb-6"
                                    >
                                        <img
                                            className="h-16"
                                            src="/images/flex-ui/flex-circle-green.svg"
                                            alt=""
                                        />
                                    </Link>
                                    <h3 className="mb-4 text-2xl md:text-3xl font-bold">
                                        Sign in to your account
                                    </h3>
                                    {!!error && (
                                        <h4 className="mb-4 text-md md:text-xl font-bold text-red-500">
                                            {error}
                                        </h4>
                                    )}
                                </div>
                                <form onSubmit={submitHandler}>
                                    <div className="mb-6">
                                        <label
                                            className="block mb-2 text-coolGray-800 font-medium"
                                            htmlFor=""
                                        >
                                            Username
                                        </label>
                                        <input
                                            className="appearance-none block w-full p-3 leading-5 text-coolGray-900 border border-coolGray-200 rounded-lg shadow-md placeholder-coolGray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                                            type="text"
                                            placeholder="your username"
                                            onChange={(e) =>
                                                setUsername(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label
                                            className="block mb-2 text-coolGray-800 font-medium"
                                            htmlFor=""
                                        >
                                            Password
                                        </label>
                                        <input
                                            className="appearance-none block w-full p-3 leading-5 text-coolGray-900 border border-coolGray-200 rounded-lg shadow-md placeholder-coolGray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                                            type="password"
                                            placeholder="your password"
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="flex flex-wrap items-center justify-between mb-6">
                                        <div className="w-full md:w-1/2">
                                            <label className="relative inline-flex items-center">
                                                <input
                                                    className="form-checkbox appearance-none"
                                                    type="checkbox"
                                                    value=""
                                                    checked={rememberMe}
                                                    onChange={() =>
                                                        setRememberMe(
                                                            !rememberMe
                                                        )
                                                    }
                                                />
                                                <img
                                                    className="absolute top-1/2 transform -translate-y-1/2 left-0"
                                                    src="/images/flex-ui/checkbox-icon.svg"
                                                    alt=""
                                                />
                                                <span className="ml-7 text-xs text-coolGray-800 font-medium">
                                                    Remember me
                                                </span>
                                            </label>
                                        </div>
                                        <div className="w-full md:w-auto mt-1">
                                            <Link
                                                to={"/forgot-password"}
                                                className="inline-block text-xs font-medium text-green-500 hover:text-green-600"
                                            >
                                                Forgot your password?
                                            </Link>
                                        </div>
                                    </div>
                                    <button
                                        className="inline-block py-3 px-7 mb-6 w-full text-base text-green-50 font-medium text-center leading-6 bg-green-500 hover:bg-green-600 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 rounded-md shadow-sm"
                                        type="submit"
                                        disabled={!canLogin || loading}
                                    >
                                        {loading && (
                                            <svg
                                                role="status"
                                                className="inline mr-3 w-4 h-4 text-white animate-spin"
                                                viewBox="0 0 100 101"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                    fill="#E5E7EB"
                                                />
                                                <path
                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        )}
                                        Sign In
                                    </button>
                                    <p className="text-center">
                                        <span className="text-xs font-medium">
                                            Don’t have an account?{" "}
                                        </span>
                                        <Link
                                            to={"/signup"}
                                            className="inline-block text-xs font-medium text-green-500 hover:text-green-600 hover:underline"
                                        >
                                            Sign up
                                        </Link>
                                    </p>
                                </form>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 px-4">
                            <div className="relative mx-auto md:mr-0 max-w-max">
                                <img
                                    className="absolute z-10 -left-14 -top-12 w-28 md:w-auto"
                                    src="/images/flex-ui/circle3-yellow.svg"
                                    alt=""
                                />
                                <img
                                    className="absolute z-10 -right-7 -bottom-8 w-28 md:w-auto"
                                    src="/images/flex-ui/dots3-blue.svg"
                                    alt=""
                                />
                                <img
                                    className="relative rounded-7xl"
                                    src="/images/junglebus-jpeg-1660049038186.webp"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
