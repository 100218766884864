import { Link } from "react-router-dom";

export const Footer = function () {
    return (
        <section
            className="bg-white"
            style={{
                backgroundImage: "url('/images/flex-ui/pattern-white.svg')",
                backgroundPosition: "center",
            }}
        >
            <div className="container px-4 mx-auto">
                <div className="flex flex-wrap pt-24 pb-12 -mx-4">
                    <div className="w-full md:w-1/2 lg:w-4/12 px-4 mb-16 lg:mb-0">
                        <Link to="/" className="inline-block mb-4">
                            <img
                                className="h-8"
                                src="/images/gorillapool.png"
                                alt=""
                            />
                        </Link>
                        <p className="text-base md:text-md text-coolGray-500 font-medium lg:w-64">
                            GorillaPool is one of the leading miners in the
                            Bitcoin ecosystem. Visit our{" "}
                            <a
                                href="https://gorillapool.com/"
                                target="_blank"
                                style={{ textDecoration: "underline" }}
                            >
                                homepage
                            </a>{" "}
                            for more information about GorillaPool
                        </p>
                    </div>
                    <div className="w-full md:w-1/4 lg:w-2/12 px-4 mb-16 lg:mb-0">
                        {/*
                        <h3 className="mb-5 text-lg font-bold text-coolGray-900">Product</h3>
                        <ul>
                            <li className="mb-4">
                                <Link
                                    className="inline-block text-coolGray-500 hover:text-coolGray-600 font-medium"
                                    to="/features"
                                >
                                    Features
                                </Link>
                            </li>
                            <li className="mb-4">
                                <Link
                                    className="inline-block text-coolGray-500 hover:text-coolGray-600 font-medium"
                                    to="/resources"
                                >
                                    Resources
                                </Link>
                            </li>
                            <li className="mb-4">
                                <Link
                                    className="inline-block text-coolGray-500 hover:text-coolGray-600 font-medium"
                                    to="/pricing"
                                >
                                    Pricing
                                </Link>
                            </li>
                        </ul>
                        */}
                    </div>
                    <div className="w-full md:w-1/4 lg:w-2/12 px-4 mb-16 lg:mb-0">
                        <h3 className="mb-5 text-lg font-bold text-coolGray-900">
                            Support
                        </h3>
                        <ul>
                            <li className="mb-4">
                                <a
                                    className="inline-block text-coolGray-500 hover:text-coolGray-600 font-medium"
                                    href="https://twitter.com/MineLikeAnApe"
                                    target="_blank"
                                >
                                    Twitter
                                </a>
                            </li>
                            <li className="mb-4">
                                <a
                                    className="inline-block text-coolGray-500 hover:text-coolGray-600 font-medium"
                                    href="https://discord.gg/2dgVhS8kcZ"
                                    target="_blank"
                                >
                                    Discord
                                </a>
                            </li>
                            <li className="mb-4">
                                <a
                                    className="inline-block text-coolGray-500 hover:text-coolGray-600 font-medium"
                                    href="https://junglebus.gorillapool.io/docs"
                                    target="_blank"
                                >
                                    Docs
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="w-full md:w-1/3 lg:w-4/12 px-4">
                        <h3 className="mb-5 text-lg font-bold text-coolGray-900">
                            Newsletter
                        </h3>
                        <div className="flex flex-wrap">
                            <div className="w-full lg:flex-1 py-1 lg:py-0 lg:mr-3">
                                <input
                                    className="px-3 w-full h-12 text-coolGray-900 outline-none placeholder-coolGray-500 border border-coolGray-200 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 rounded-lg shadow-xsm"
                                    placeholder="Your email"
                                    disabled={true}
                                />
                            </div>
                            <div className="w-full lg:w-auto py-1 lg:py-0">
                                <button
                                    className="inline-block py-4 px-5 w-full leading-4 text-green-50 font-medium text-center bg-green-500 hover:bg-green-600 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 rounded-md shadow-sm"
                                    onClick={() => alert("Not implemented.")}
                                    disabled={true}
                                >
                                    Subscribe
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-b border-coolGray-100"></div>
            <p className="py-10 md:pb-16 text-sm text-coolGray-400 font-medium text-center">
                © 2022{" "}
                <a
                    href="https://gorillapool.com/"
                    target="_blank"
                    rel="noreferrer"
                >
                    GorillaPool
                </a>
                . All rights reserved.
            </p>
        </section>
    );
};
